@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/general-sans");

body {
  margin: 0;
  font-family: "General Sans", "Inter", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  letter-spacing: -2%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../public/assets/calendar-icon.png") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

input[type="file"]::file-selector-button {
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  border: 0;
  background-color: #fff2db;
  color: black;
  opacity: 1;
  transition: 1s;
}

input[type="file"]::file-selector-button:hover {
  opacity: 0.9;
  background-color: #fff2db;
}

input[type="checkbox"] {
  accent-color: #318ec9;
}

input[type="radio"] {
  accent-color: #318ec9;
}